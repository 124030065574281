import request from '../../utils/axios';
// 宝汇
const base = 'https://tocms.rwkad.com';
// 鑫盛
const xsApi = '/api/proxy';
// 测试
const textApi = 'https://testwww.rlc525.com/api/proxy'
// 线上
const originApi = window.location.origin +'/api/proxy';

// 美国失业率报告
export function getSYLApi(data) {
    return request({
        url: originApi + '/v4/USUP001',
        method: 'POST',
        data
    })
}
// 美国非农就业
export function getFNApi(data) {
    return request({
        url: originApi + '/v4/NF001',
        method: 'POST',
        data
    })
}